export const UserRoles = {
	VERATA_CUSTOMER_SERVICE_MANAGER: 'VerataCustomerServiceManager',
	VERATA_CUSTOMER_ADMIN: 'VerataCustomerAdmin',
	HOSPITAL_VIEWER: 'HospitalViewer',
	PROCESSOR: 'Processor',
	OLIVE_ADMIN: 'OliveAdmin',
	SUPPORT: 'HumataSupport',
	TRAINEE: 'Trainee',
	MANAGER: 'Manager',
	DATA_INGEST_APP_TOOLS: 'DataIngestAppTools',
	SYS_AUTOMATION: 'SysAutomation',
	READ_ONLY: 'ReadOnly',
	VERATA_PROCESSOR: 'VerataProcessor',
	DENIALS: 'Denials',
	REPORT_VIEWER: 'ReportViewer'
  };