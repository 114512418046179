

export class ErrorService {
    static isBadRequest(error) {
        return error?.response?.status === 400
    }

    static isUnAuthorized(error) {
        return error?.response?.status === 401
    }

    static isLocked(error) {
        return error?.response?.status === 423
    }

    static preconditionRequired(error) {
        return error?.response?.status === 428
    }

    static isUnavailableForLegalReasons(error) {
        return error?.response?.status === 451
    }

    static hasInsecureConnection(window) {
        return window.location.protocol === 'http:'
    }

    static hasPasswordExpired(error) {
        return error?.response?.data === 'password expired'
    }

    static hasConflictInRequest(error) {
        return error?.response?.status === 409
    }

    static responseIsUndefined(error) {
        return typeof(error.response) === 'undefined'
    }
}