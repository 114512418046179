import Vue from 'vue'
import ENV from '@/config/environment'
import store from '@/store'

export default {
    telemetryInitializer(envelope) {
        envelope.data.environment = ENV.env_name
        envelope.data.build = VERSION || 'Local'
        envelope.data.commit = COMMITHASH
        envelope.data.windowHeight = window?.innerHeight || 'unknown'
        envelope.data.windowWidth = window?.innerWidth || 'unknown'
        envelope.data.screenHeight = window?.screen?.availHeight || 'unknown'
        envelope.data.screenWidth = window?.screen?.availWidth || 'unknown'
        envelope.data.userGuid = store.state.currUser.paPersonId || 'unknown'
    },
    logEvent(eventName, data) {
        Vue.appInsights.trackEvent({
            name: eventName,
            properties: {
                ...data,
            }
        })
    },
    trackSignInEvent() {
        this.logEvent('sign_in', {
            date: Date.now(),
            customer: store.state.currUser.organization,
            userName: `${store.state.currUser.firstName} ${store.state.currUser.lastName}`,
            browser: Vue.prototype.$browserDetect.meta.name,
            browserVersion: Vue.prototype.$browserDetect.meta.version
        })
    },
    trackCustomFilterSelectionEvent(data) {
        this.logEvent('custom_filter_selected', data)
    },
    trackRequestTabSelectedEvent(activeTab, selectedPayerTab) {
        const tabs = {
            0: 'overview',
            1: 'medical_necessity',
            2: 'documentation'
        }

        this.logEvent('active_request_tab', {
            activeTab: activeTab < 3 ? tabs[activeTab] : `${selectedPayerTab}_payer`
        })
    },
    trackPortalAssistantEvent(portal, selectedPayerTab) {
        const selectedPortal = store.state.portalList.find(pl => pl.id === portal)

        this.logEvent('portal_assistant_displayed', {
            selectedPortal: selectedPortal?.description || null,
            selectedPayerTab
        })
    },
    trackHideFiltersEvent(displayed) {
        this.logEvent('worklist_filters_hide_show_toggle', { displayed })
    },
    logDraftUpdated(action, params) {
        try {
            this.logEvent('draft-updated', { action, ...params })
        } catch(err) {
            console.warn(err)
        }
    },
    logTimeForGetFilterCounts(timespanMilliseconds, systemFilters, customUserFilters) {
        try {
            this.logEvent('time-for-get-filter-counts-call', { 
                responseTime: timespanMilliseconds,
                systemFilters,
                customUserFilters
            })
        } catch(err) {
            console.warn(err)
        }
    },
    logTimeWaitingForDocumentsAnalyzed(timespanMilliseconds) {
        try {
            this.logEvent('time-waiting-for-documents-analyzed', { timespanMilliseconds })
        } catch(err) {
            console.warn(err)
        }
    },
    logAttestationSkipped(stemp) {
        try {
            this.logEvent('attestation-skipped', { stemp })
        } catch (err) {
            console.warn(err)
        }
    },
    logAiAnswerChanged(questionId, stempCode) {
        try {
            this.logEvent(
                'ai-attestation-answer-changed', {
                stemp: stempCode,
                questionId,
            })
        } catch (err) {
            console.warn(err)
        }
    },
    logShowNewVersionMessage() {
        try {
          this.logEvent('new-version-message-displayed', {})
        } catch (err) {
          console.warn(err)
        }
    },
    logWorklistNavigation({index, direction, prevRequestId, requestId, nextRequestId}) {
        try {
            this.logEvent(
                'worklist-navigation', {
                index,
                direction,
                prevRequestId,
                requestId,
                nextRequestId
            })
        } catch (err) {
            console.warn(err)
        }
    },
    logMFEFailedToAttach() {
        try {
            this.logEvent('attestation-mfe-failed-to-attach')
        } catch (err) {
            console.warn(err)
        }
    }

}