import { DataExportApi } from './dataExportApi';


export class BlobService {

    static async download({ notification }) {
        return new Promise(async (resolve, reject) => {
            if (!notification) reject(new Error("Must provide a notification"));

            try {
                const response = await DataExportApi.getReport(notification.id);
                const contentType = "binary";
                const newBlob = new Blob([response.data], { type: contentType });

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob, notification.filename);
                } else {
                    const data = window.URL.createObjectURL(newBlob);
                    let link = document.createElement('a');
                    link.href = data;
                    link.download = notification.filename;
                    link.click();
                    setTimeout(function() {
                        window.URL.revokeObjectURL(data);
                        resolve();
                    }, 100);
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}
