export const REQUIREMENT_ID_TYPES = {
  // A single requirement
  REQUIREMENT: 0,
  // A collection of requirements
  REQUIREMENT_GROUP: 1,
  // A collection of groups
  REQUIREMENT_LIST: 2,
  // Overall PA Requirements
  PA_REQUIREMENT: 3,
};

export const PLEASE_REFERENCE_THE_ATTACHED_POLICY_LINK_TO_REVIEW_MEDICAL_NECESSITY_GUIDELINES =
  "Please reference the attached policy link to review medical necessity guidelines.";
