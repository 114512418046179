import ENV_CONFIG from "@/config/environment";

export class UtilityService {
  static getEnvironmentName() {
    const envName = ENV_CONFIG.env_name;
    if (envName != "Production") {
      return "to " + envName;
    } else {
      return "Pathway";
    }
  }

  static isNullOrUndefined(value) {
    if (value == null || value == undefined) return true;

    return false;
  }

  static isEmptyNullOrUndefined(value) {
    if (this.isNullOrUndefined(value)) return true;

    if (value == "" || value.length == 0) {
      return true;
    }
    return false;
  }
  
  static hasNumbersInString(str) {
    const regex = /\d/;
    return regex.test(str);
  }

  static isEmptyDateRange(dateRange) {
    if (
      dateRange &&
      this.isEmptyNullOrUndefined(dateRange[0]) &&
      this.isEmptyNullOrUndefined(dateRange[1])
    ) {
      return true;
    }

    return false;
  }

  static isNotNullEmptyOrAnUndefinedString(value) {
    if (
      value != null &&
      value.toLowerCase() != "undefined" &&
      value != undefined &&
      value != ""
    ) {
      return true;
    }
    return false;
  }

  static isCPTValid (possibleCpt) {
    return /(^[A-Z0-9]\d{4,4}$)|(^\d{5}-\d{5}$)|(^\d{4,4}[A-Z0-9]$)/.test(possibleCpt);
  }

  static getCPTError (value) {
    if(!this.isEmptyNullOrUndefined(value)) {
      if(!this.isCPTValid(value)) {
        return 'Invalid CPT Code.'
      }
    }
    return ''
  }

  static isNPIValid (possibleNpi)  {
    return possibleNpi && possibleNpi.length === 10  && !isNaN(Number(possibleNpi))
  }

  static getNPIError (value) {
    if(!this.isEmptyNullOrUndefined(value)) {
      if(!this.isNPIValid(value)) {
        return 'Invalid Servicing Facility NPI. Must be 10 Digits.'
      }
    }
    return ''
  }

  static isServiceTypeValid (possibleServiceType)  {
    return /^[a-zA-Z0-9]*$/.test(possibleServiceType)
  }

  static getServiceTypeError (value) {
    if(!this.isEmptyNullOrUndefined(value)) {
      if(!this.isServiceTypeValid(value)) {
        return 'Invalid Service Type.'
      }
    }
    return ''
  }
}
