// export { TextAreaAutoScroll } from './autoScroll'
import Vue from 'vue'
function autosize(){
    const el = this;
    setTimeout(function(){
      el.style.cssText = 'height:auto; padding:0';
      // for box-sizing other than "content-box" use:
      // el.style.cssText = '-moz-box-sizing:content-box';
      el.style.cssText = 'height:' + el.scrollHeight + 'px';
    }, 0)
  }
  Vue.directive('textAreaAutoScroll', (el, binding) => {
    // this will be called for both `mounted` and `updated`
    el.addEventListener('keydown', autosize);
})