function initialState() {
    return {
        gorillaLinkToken: null
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        SET_GORILLA_LINK_TOKEN: ({ commit }, token) => {
            commit('SET_GORILLA_LINK_TOKEN', token)
        }
    },
    mutations: {
        SET_GORILLA_LINK_TOKEN: (state, token) => {
            state.gorillaLinkToken = token
        }
    }
}