<template>
    <div v-if="isDetailsShown" class="overlayContainer">
        <div class="overlayContent">
            <div id="closeModal" class="close" @click="hideDetails">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="overlayInnerContent" v-html="detailsToShow"></div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    name: "UMSPopupModal",

    computed: {
        ...mapState("displayMessages", ["isDetailsShown", "detailsToShow"])
    },
    methods: {
        hideDetails: function() {
            this.$store.dispatch('displayMessages/TOGGLE_DISPLAY_DETAILS', { shown: false });
        }
    }
    
}
</script>