function initialState() {
  return {
    submissionInProgress: false,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    SUBMISSION_IN_PROGRESS: ({ commit }, submissionInProgress) => {
      commit("SET_SUBMISSION_IN_PROGRESS", submissionInProgress);
    },
  },
  mutations: {
    SET_SUBMISSION_IN_PROGRESS: (state, submissionInProgress) => {
      state.submissionInProgress = submissionInProgress;
    },
  },
};
