import axios from "axios";

import ENV_CONFIG from "@/config/environment";

const API = ENV_CONFIG.env_api;

const handleRequestError = (error) => {
    let errorMessage;

    if (error.response) {
        // The request was made, but the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = `Server responded with status: ${error.response.status}`;
    } else if (error.request) {
        // The request was made, but no response was received
        errorMessage = `No response received from the server.`;
    } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = `Error setting up the request: ${error.message}`;
    }

    const customError = new Error(errorMessage);
    customError.originalError = error; // Preserve the original error information

    throw customError;
};

export class DataExportApi {
    static async allPaRequests(startEndRange) {
        try {
            let url = `${API}DataExport/AllPaRequests`;
            if (startEndRange.startDate !== null && startEndRange.endDate !== null) {
                url += `/${startEndRange.startDate}/${startEndRange.endDate}`;
            }
            return await axios.get(url, {
                headers: {Authorization: "Bearer " + sessionStorage.getItem("token")},
            });
        } catch (error) {
            handleRequestError(error);
        }
    }

    static async worklistByFilters(filterParams) {
        try {
            return await axios.post(`${API}DataExport/WorklistByFilters`, filterParams, {
                headers: {Authorization: "Bearer " + sessionStorage.getItem("token")},
            });
        } catch (error) {
            handleRequestError(error);
        }
    }

    static async archivedByFilters(filterParams) {
        try {
            return await axios.post(`${API}DataExport/ArchivedByFilters`, filterParams, {
                headers: {Authorization: "Bearer " + sessionStorage.getItem("token")},
            });
        } catch (error) {
            handleRequestError(error);
        }
    }

    static async getReport(id) {
        try {
            return await axios.get(`${API}DataExport/GetReportData/${id}`, {
                headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
                responseType: 'arraybuffer',
            });
        } catch (error) {
            handleRequestError(error);
        }
    }

    static async getStatus(id) {
        try {
            return await axios.get(`${API}DataExport/GetStatus/${id}`, {
                headers: { Authorization: "Bearer " + sessionStorage.getItem("token") }
            });
        } catch (error) {
            handleRequestError(error);
        }
    }

}