import axios from "axios"
import ENV_CONFIG from '@/config/environment'
import Vue from 'vue'
import Vuex from 'vuex'
import store from '../../index';

Vue.use(Vuex)

export const directSubmitEvicore = {
    namespaced: true,
    state: {
        eviCoreDirectSubmitAttachDocumentsError: null,
        eviCoreDirectSubmitDocumentsAttached: false,
    },
    actions: {
		EVICORE_DIRECT_SUBMIT_SEND_REQUEST: async function({ commit, dispatch }, data) {
            store.dispatch('submission/SUBMISSION_IN_PROGRESS', true)

            await axios.post(`${ENV_CONFIG.env_api}Evicore/SubmitCase`, data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                    Pragma: "no-cache",
                },
            })
            .then((response) => {
                store.dispatch('submission/SUBMISSION_IN_PROGRESS', false)
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch('auth/SIGN_OUT');
                }

                store.dispatch('submission/SUBMISSION_IN_PROGRESS', false)
            });

            return;
        },
        EVICORE_DIRECT_SUBMIT_ATTACH_DOCUMENTS: async function({ commit, dispatch }, data) {
            await axios.post(`${ENV_CONFIG.env_api}Evicore/AttachDocuments`, data, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                    Pragma: "no-cache",
                },
            })
            .then((response) => {
                commit('SET_EVICORE_DIRECT_SUBMIT_DOCUMENTS_ATTACHED', true);
            })
            .catch(err => {
                store.dispatch("SET_GLOBAL_MESSAGE", [
                    "error",
                    `${err.response.data}`,
                ]);

                if (err.response && err.response.status === 401) {
                    dispatch('auth/SIGN_OUT');
                }

                commit('SET_EVICORE_DIRECT_SUBMIT_DOCUMENTS_ATTACHED', false);
                commit('SET_EVICORE_DIRECT_SUBMIT_ATTACH_DOCUMENTS_ERROR', err.response?.data.Message ?? err.response.data );
            });

            return;
        }
    },
    mutations: {
		SET_EVICORE_DIRECT_SUBMIT_ATTACH_DOCUMENTS_ERROR: (state, data) => {
            state.eviCoreDirectSubmitAttachDocumentsError = data
        },
        SET_EVICORE_DIRECT_SUBMIT_DOCUMENTS_ATTACHED: (state, data) => {
            state.eviCoreDirectSubmitDocumentsAttached = data
        },
    },
    getters: {
        
    }
};