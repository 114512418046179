<template>
  <div
    v-if="isShown"
    class="newVersionMessage animate__animated animate__fadeInDown">
    <img src="/images/new-version.svg" alt="" aria-hidden="true" />
    <span>
      Pathway has been updated to a new version, your page will automatically refresh 
      <span v-if="uploadProgress < 1">
        in
        <b>{{ refreshInMilliseconds | durationFromNow }}</b>.</span>
      <span v-if="uploadProgress >= 1">when uploads complete. </span>
      <br />
      <span v-if="uploadProgress < 1">
        Or get the latest version now:
        <a ref="refreshButton" href="#" @click.prevent="refresh">REFRESH</a>
      </span>
      <span v-if="uploadProgress >= 1">&nbsp;</span>
    </span>
  </div>
</template>

<script>
    import { mapState } from 'vuex'
    import ENV_CONFIG from '../config/environment'
    import logger from '@/shared/logger'
    import axios from 'axios'
    import { CACHE_KEYS } from '@/shared/constants/cacheKeys'
    import { CacheService } from '@/services/cacheService'

export default {
    name: 'NewVersionModal',
    data() {
      return {
        intervalId: null,
        refreshInMilliseconds: 120000,
        countdownInterval: 1000,
        checkVersionInterval: 600000,
        version: null,
        isShown: false,
        waitingForDocumentsToUpload: false,
      }
    },
    computed: {
        ...mapState({
            uploadProgress: ['uploadProgress']
        })
    },
    mounted() {
      this.checkVersion()
      
      this.intervalId = setInterval(
        () => this.checkVersion(),
        this.checkVersionInterval)
    },
    destroyed() {
      clearInterval(this.intervalId)
    },
    watch: {
      uploadInProgress(value) {
        if (!value && this.waitingForDocumentsToUpload) {
          this.refresh()
        }
      },
    },
    methods: {
      refresh() {
        window.location.reload(true)
      },
      setUIVersion( version ) {
        if(!this.version) {
            this.version = version
            return
        }
        if(this.version !== version) {
            this.version = version
            this.display()
            logger.logShowNewVersionMessage()
        }
      },
      checkVersion() {        
        var cacheKey = CACHE_KEYS.UIVersion;
        var uiVersion = CacheService.getItem( cacheKey );

        if ( uiVersion != null ){
          this.setUIVersion( uiVersion )
        } else {
          axios.get(`${ENV_CONFIG.env_api}Version/GetUIVersion`, {
          }).then((response) => {
              CacheService.setItem( cacheKey, response.data )
              this.setUIVersion( response.data )
          })
        }
      },
      display() {
        this.isShown = true
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          () => {
            this.refreshInMilliseconds -= this.countdownInterval
            if (this.refreshInMilliseconds <= 0) {
              if (this.uploadProgress >= 1) {
                this.waitingForDocumentsToUpload = true
                return
              }
              this.refresh()
            }
          },
          this.countdownInterval)
      },
    },
  }
</script>

