// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Vddl from 'vddl'
import VueSelect from '@desislavsd/vue-select'
import browserDetect from "vue-browser-detect-plugin"
import ENV from '@/config/environment'
import VueAppInsights  from 'vue-application-insights'
import logger from '@/shared/logger'
import MFEloader from '@/shared/plugins/MFELoader'
import VTooltip from 'v-tooltip'
import PortalVue from 'portal-vue'
import '@/shared/filter.js'
import '@/shared/directives'
import '@/css/style.scss'
import 'animate.css'


import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'

Vue.config.productionTip = false

Vue.use(browserDetect)

// https://v2.portal-vue.linusb.org/guide/getting-started.html#setup
Vue.use(PortalVue)

try {
  if (ENV.app_insights) {
    Vue.use(VueAppInsights, {
      baseName: ENV.env_name,
      appInsightsConfig: {
        connectionString: ENV.app_insights,
      },
      router,
    })
    Vue.appInsights.addTelemetryInitializer(logger.telemetryInitializer)
  }
} catch (err) {
  console.warn(err)
}
Vue.use(MFEloader)
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

Vue.use(Vddl);
Vue.use(VueSelect, { /* options */ }) // registers <v-select /> globally
Vue.use(VTooltip)
Vue.directive('testid', function(el,binding){
  if(ENV.env_name.toLowerCase() != 'production') {
    el.dataset.testid = binding.value
  }
})
