function initialState() {
  return {
    activeColumnHeaders: [],
    hasMultiSortActivated: JSON.parse(sessionStorage.getItem('archiveHasMultiSortActivated') || "false"),
  };
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    TURN_MULTI_SORT_ON: ({ commit }) => {
      commit('SET_HAS_MULTI_SORT_ACTIVATED', true)
    },
    TURN_MULTI_SORT_OFF: ({ commit }) => {
      commit('SET_HAS_MULTI_SORT_ACTIVATED', false)
    },
    SET_ACTIVE_COLUMN_HEADERS: ({ commit }, activeColumnHeaders) => {
      commit('SET_ACTIVE_COLUMN_HEADERS', activeColumnHeaders)
    },
    RESET_ACTIVE_COLUMN_HEADERS: ({ commit }) => {
      commit('SET_ACTIVE_COLUMN_HEADERS', [])
    },
    ADD_ACTIVE_COLUMN_HEADER: ({ commit }, activeColumnHeader) => {
      commit('ADD_ACTIVE_COLUMN_HEADER', activeColumnHeader)
    },
    UPDATE_ACTIVE_COLUMN_HEADER_STATE: ({ commit, state }, activeColumnHeaderToUpdate) => {
      commit(
        'SET_ACTIVE_COLUMN_HEADERS',
        state.activeColumnHeaders.map(
          activeColumnHeader => {
            if (activeColumnHeader.field === activeColumnHeaderToUpdate.field) {
              return {
                ...activeColumnHeader,
                state: activeColumnHeaderToUpdate.state
              }
            }

            return activeColumnHeader
          }
        )
      )
    },
    REMOVE_ACTIVE_COLUMN_HEADER: ({ commit, state }, activeColumnHeaderToRemove) => {
      commit(
        'SET_ACTIVE_COLUMN_HEADERS',
        state.activeColumnHeaders.filter(
          activeColumn => activeColumn.field !== activeColumnHeaderToRemove.field
        )
      )
    },
    RESET: ({ commit }) => {
      commit("RESET_ACTIVE_COLUMN_HEADERS")
      commit("TURN_MULTI_SORT_OFF")
    },
  },
  mutations: {
    SET_HAS_MULTI_SORT_ACTIVATED: (state, hasMultiSortActivated) => {
      state.hasMultiSortActivated = hasMultiSortActivated
      sessionStorage.setItem("archiveHasMultiSortActivated", hasMultiSortActivated)
    },
    SET_ACTIVE_COLUMN_HEADERS: (state, activeColumnHeaders) => {
      state.activeColumnHeaders = activeColumnHeaders
    },
    ADD_ACTIVE_COLUMN_HEADER: (state, activeColumnHeader) => {
      state.activeColumnHeaders = [
        ...state.activeColumnHeaders,
        activeColumnHeader
      ]
    },
  },
  getters: {},
};
