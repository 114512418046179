import { WorklistFilters } from "@/shared/models/worklist";
import { Worker } from "@/shared/models/worker";
import { UtilityService } from "@/services/utility";
import { WorklistService } from "@/services/worklist"
import PaginatedFilterMapping from "@/services/paginatedFilterMapping";
import moment from "moment";

function initialState() {
  return {
    filters: new WorklistFilters(),
    saveFilterName: "",
    defaultFilter: "all_requests",
    defaultFilterToSearchOnStart: null,
    activeColumnHeaders: [],
    filterUpdates: JSON.parse(sessionStorage.getItem('filterUpdates') || '{}'),
    hasMultiSortActivated: JSON.parse(sessionStorage.getItem('hasMultiSortActivated') || "false"),
    filterToEdit: null,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    TURN_MULTI_SORT_ON: ({ commit }) => {
      commit('SET_HAS_MULTI_SORT_ACTIVATED', true)
    },
    TURN_MULTI_SORT_OFF: ({ commit }) => {
      commit('SET_HAS_MULTI_SORT_ACTIVATED', false)
    },
    SET_WORKLIST_FILTERS: ({ commit }, filters) => {
      commit("SET_WORKLIST_FILTERS", filters);
    },
    CLEAR_WORKLIST_FILTERS: ({ commit }) => {
      commit('SET_WORKLIST_FILTERS', {})
    },
    UPDATE_WORKLIST_FILTERS: ({ commit, state }, filters) => {
      commit("SET_WORKLIST_FILTERS", {
        ...state.filters,
        ...filters,
      });
    },
    SET_FILTER_NAME: ({ commit }, saveFilterName) => {
      commit("SET_FILTER_NAME", saveFilterName);
    },
    SET_DEFAULT_FILTER: ({ commit }, defaultFilter) => {
      commit('SET_DEFAULT_FILTER', defaultFilter)
    },
    SET_DEFAULT_FILTER_TO_SEARCH_ON_START: ({ commit }, defaultFilterToSearchOnStart) => {
      commit('SET_DEFAULT_FILTER_TO_SEARCH_ON_START', defaultFilterToSearchOnStart)
    },
    SET_ACTIVE_COLUMN_HEADERS: ({ commit }, activeColumnHeaders) => {
      commit('SET_ACTIVE_COLUMN_HEADERS', activeColumnHeaders)
    },
    RESET_ACTIVE_COLUMN_HEADERS: ({ commit }) => {
      commit('SET_ACTIVE_COLUMN_HEADERS', [])
    },
    ADD_ACTIVE_COLUMN_HEADER: ({ commit }, activeColumnHeader) => {
      commit('ADD_ACTIVE_COLUMN_HEADER', activeColumnHeader)
    },
    UPDATE_ACTIVE_COLUMN_HEADER_STATE: ({ commit, state }, activeColumnHeaderToUpdate) => {
      commit(
        'SET_ACTIVE_COLUMN_HEADERS',
        state.activeColumnHeaders.map(
          activeColumnHeader => {
            if (activeColumnHeader.field === activeColumnHeaderToUpdate.field) {
              return {
                ...activeColumnHeader,
                state: activeColumnHeaderToUpdate.state
              }
            }

            return activeColumnHeader
          }
        )
      )
    },
    REMOVE_ACTIVE_COLUMN_HEADER: ({ commit, state }, activeColumnHeaderToRemove) => {
      commit(
        'SET_ACTIVE_COLUMN_HEADERS',
        state.activeColumnHeaders.filter(
          activeColumn => activeColumn.field !== activeColumnHeaderToRemove.field
        )
      )
    },
    SET_FILTER_UPDATES: ({ commit }, updates) => {
      commit("SET_FILTER_UPDATES", updates)
    },
    RESET_FILTER_UPDATES: ({ commit}) => {
      commit("RESET_FILTER_UPDATES")
    },
    RESET: ({ commit }) => {
      commit("RESET")
    },
    SET_FILTER_TO_EDIT: ({ commit }, filterToEdit) => {
      commit('SET_FILTER_TO_EDIT', filterToEdit)
    },
    RESET_FILTER_TO_EDIT: ({ commit }) => {
      commit('SET_FILTER_TO_EDIT', null)
    }
  },
  mutations: {
    SET_FILTER_TO_EDIT: (state, filterToEdit) => {
      state.filterToEdit = filterToEdit
    },
    SET_HAS_MULTI_SORT_ACTIVATED: (state, hasMultiSortActivated) => {
      state.hasMultiSortActivated = hasMultiSortActivated
      sessionStorage.setItem("hasMultiSortActivated", hasMultiSortActivated)
    },
    SET_ACTIVE_COLUMN_HEADERS: (state, activeColumnHeaders) => {
      state.activeColumnHeaders = activeColumnHeaders
    },
    ADD_ACTIVE_COLUMN_HEADER: (state, activeColumnHeader) => {
      state.activeColumnHeaders = [
        ...state.activeColumnHeaders,
        activeColumnHeader
      ]
    },
    RESET: (state) => {
      state.filters = new WorklistFilters()
      state.saveFilterName = ""
      state.defaultFilter = "all_requests"
      state.defaultFilterToSearchOnStart = null
      state.activeColumnHeaders = []
      state.filterUpdates = JSON.parse(sessionStorage.getItem('filterUpdates') || '{}')
      state.hasMultiSortActivated = JSON.parse(sessionStorage.getItem('hasMultiSortActivated') || "false")
      state.filterToEdit = null
    },
    RESET_FILTER_UPDATES: (state) => {
      state.filterUpdates = {}
      sessionStorage.setItem('filterUpdates', JSON.stringify(state.filterUpdates))
    },
    SET_FILTER_UPDATES: (state, updates) => {
      state.filterUpdates = {
        ...state.filterUpdates,
        ...updates
      }

      sessionStorage.setItem('filterUpdates', JSON.stringify(state.filterUpdates))
    },
    SET_DEFAULT_FILTER_TO_SEARCH_ON_START: (state, defaultFilterToSearchOnStart) => {
      state.defaultFilterToSearchOnStart = defaultFilterToSearchOnStart
    },
    SET_DEFAULT_FILTER: (state, defaultFilter) => {
      state.defaultFilter = defaultFilter
    },
    SET_FILTER_NAME: (state, saveFilterName) => {
      state.saveFilterName = saveFilterName;
    },
    SET_WORKLIST_FILTERS: (state, filters) => {
      state.filters = new WorklistFilters(filters);
    },
  },
  getters: {
    filterSortHasBeenModified: (state, getters, rootState, rootGetters) => {
      if (getters.getMatchingFilterFromCurrent.length === 0) return false

      const currentFilter = getters.getMatchingFilterFromCurrent[0]
      const storedSortsInSession = rootState.worklistSortInfo

      /**
       * If we don't info in session about sorts then return false
       * since we can't tell is the filters sort properties have been
       * modified.
       */
      if (storedSortsInSession.length === 0) return false
      /**
       * if what we have currently in session is more, or less than what the save filter
       * currently has then return true because the sorts were modified
       */
      if (storedSortsInSession.length > currentFilter?.sort?.length) return true
      if (storedSortsInSession.length < currentFilter?.sort?.length) return true

      if (currentFilter?.sort) {
        /**
         * If the current filter has a saved sort, check to see if 
         * the updates match the saved sorts and make sure that the
         * direction hasn't changed
         */
        
        const sortUpdates = currentFilter.sort.filter(
          filter => {
            const foundFilterInSession = storedSortsInSession.find(
              inSessionSort => inSessionSort.field === filter.field
            )
            
            if (foundFilterInSession) {
              return String(foundFilterInSession.direction) !== String(filter.dir)
            }
            
            /**
             * If a filter in session was not found in the list
             * of sort properties from the current filter applied,
             * the return true since the filter had been modified.
             */
            return true
          }
        )

        return sortUpdates.length > 0
      }

      return false
    },
    filterModifierHasBeenUpdated: (state, getters, rootState, rootGetters) => {
      if (getters.formattedCurrentFilter.length == 0) return false
      if (Object.keys(state.filterUpdates).length === 0) return false
      if (getters.getMatchingFilterFromCurrent.length === 0) return false

      const matchingFilter = getters.getMatchingFilterFromCurrent[0]

      const filterModifierUpdates = Object.keys(matchingFilter?.modifiers || []).filter(
        modifierKey => state.filterUpdates[modifierKey]?.operator !== matchingFilter.modifiers[modifierKey]?.operator
      )

      return filterModifierUpdates.length > 0
    },
    filterModified: (state, getters, rootState, rootGetters) => {
      return getters.currentFilter && !getters.currentFilterIsExistingFilter;
    },
    filteredProviders: (state, getters, rootState, rootGetters) => {
      if (rootState.providers && rootState.providers.length > 0) {
        return rootState.providers.filter((p) =>
          state.filters.filterByProvider.includes(p.verataPaProviderId)
        );
      }
      return [];
    },
    filteredStatusList: (state, getters, rootState, rootGetters) => {
      if (
        rootState.dataList &&
        rootState.dataList.Status &&
        rootState.dataList.Status.length > 0
      ) {
        return rootState.dataList.Status.filter((s) =>
          state.filters.filterByStatus.includes(s.Id)
        );
      }
      return [];
    },
    filteredPayerList: (state, getters, rootState, rootGetters) => {
      if (rootState.payerList && rootState.payerList.length > 0) {
        return rootState.payerList.filter((p) =>
          state.filters.filterByPayer.includes(p.insuranceCompanyId)
        );
      }
      return [];
    },
    filteredMrnList: (state, getters, rootState, rootGetters) => {
      if (state.filters.filterByMrn && state.filters.filterByMrn.length > 0) {
        if (Array.isArray(state.filters.filterByMrn)) {
          return state.filters.filterByMrn[0];
        }
        return state.filters.filterByMrn;
      }
      return null;
    },
    filteredPatientFirstNameList: (state, getters, rootState, rootGetters) => {
      if (
        state.filters.filterByPatientFirstName &&
        state.filters.filterByPatientFirstName.length > 0
      ) {
        if (Array.isArray(state.filters.filterByPatientFirstName)) {
          return state.filters.filterByPatientFirstName[0];
        }
        return state.filters.filterByPatientFirstName;
      }
      return null;
    },
    filteredEmrIdList: (state, getters, rootState, rootGetters) => {
      if (
        state.filters.filterByEmrId &&
        state.filters.filterByEmrId.length > 0
      ) {
        if (Array.isArray(state.filters.filterByEmrId)) {
          return state.filters.filterByEmrId[0];
        }
        return state.filters.filterByEmrId;
      }
      return null;
    },
    filteredCptCodeList: (state, getters, rootState, rootGetters) => {
      if (
        state.filters.filterByCptCode &&
        state.filters.filterByCptCode.length > 0
      ) {
        if (Array.isArray(state.filters.filterByCptCode)) {
          return state.filters.filterByCptCode[0];
        }
        return state.filters.filterByCptCode;
      }
      return null;
    },
    filteredProcedureDescriptionList: (state, getters, rootState, rootGetters) => {
      if (
        state.filters.filterByProcedureDescription &&
        state.filters.filterByProcedureDescription.length > 0
      ) {
        if (Array.isArray(state.filters.filterByProcedureDescription)) {
          return state.filters.filterByProcedureDescription[0];
        }
        return state.filters.filterByProcedureDescription;
      }
      return null;
    },
    procedureDateRangeNDaysInputDisplay: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return moment(
        WorklistService.convertDateFromDSL(state.filters.procedureDateRangeWithinNDays)[1]
      ).format("MM/DD/YYYY");
    },
    followUpDateRangeNDaysInputDisplay: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return moment(
        WorklistService.convertDateFromDSL(state.filters.followUpDateRangeWithinNDays)[1]
      ).format("MM/DD/YYYY");
    },
    hasRequestTags: (state, getters, rootState, rootGetters) => {
      return (
        state.filters.filterByMrn.length ||
        state.filters.filterByPatientFirstName.length ||
        state.filters.filterByPatientFirstName.length ||
        state.filters.filterByEmrId.length ||
        state.filters.filterByCptCode.length ||
        state.filters.filterByProcedureDescription.length ||
        state.filters.filterByAssigned.length ||
        state.filters.filterByProvider.length ||
        state.filters.filterByStatus.length ||
        state.filters.filterByPayer.length ||
        state.filters.filterBySubmissionMethod.length ||
        state.filters.filterBySubmissionStatus.length ||
        state.filters.filterByDepartments.length ||
        state.filters.procedureDateRange ||
        state.filters.followUpDateRange ||
        state.filters.createdDateRange ||
        state.filters.procedureDateRangeNullDatesIncluded.length ||
        state.filters.procedureDateRangeIncludePastDates.length ||
        state.filters.followUpDateRangeNullDatesIncluded.length ||
        state.filters.createdDateRangeNullDatesIncluded.length ||
        state.filters.procedureDateRangeDisplayWithDaysInput.length ||
        state.filters.followUpDateRangeDisplayWithDaysInput.length ||
        state.filters.createdDateRangeDisplayWithDaysInput.length ||
        state.filters.filterByFacility.length ||
        !UtilityService.isEmptyNullOrUndefined(state.filters.filterByMrn) ||
        !UtilityService.isEmptyNullOrUndefined(
          state.filters.filterByPatientFirstName
        ) ||
        !UtilityService.isEmptyNullOrUndefined(
          state.filters.filterByPatientLastName
        ) ||
        !UtilityService.isEmptyNullOrUndefined(state.filters.filterByEmrId) ||
        !UtilityService.isEmptyNullOrUndefined(state.filters.filterByCptCode) ||
        !UtilityService.isEmptyNullOrUndefined(state.filters.filterByProcedureDescription)
      );
    },
    hasFilterChanges: (state, getters, rootState, rootGetters) => {
      const sessionFilter = sessionStorage.getItem("worklistFilter");
      const currentJson = JSON.stringify(getters.currentFilter);

      if (currentJson === "{}") return false;
      return (
        sessionFilter !== currentJson || getters.currentFilterIsExistingFilter
      );
    },
    filteredRequests: (state, getters, rootState, rootGetters) => {
      return rootState.requests;
    },
    /**
     * Return the current filter
     */
    currentFilter: (state, getters, rootState, rootGetters) => {
      let currentFilter = {};
      getters.filterKeys.forEach((f) => {
        if (
          state.filters[f] &&
          !UtilityService.isEmptyNullOrUndefined(state.filters[f])
        ) {
          currentFilter[f] = state.filters[f];
        }
      });

      return currentFilter;
    },
    filterKeys: (state, getters, rootState, rootGetters) => {
      return Object.keys(state.filters);
    },
    worklistProviders: (state, getters, rootState, rootGetters) => {
      if (rootState.providers && rootState.providers.length > 0) {
        return rootState.providers.filter((p) => !p.inActive);
      }
      return [];
    },
    filterableStatuses: (state, getters, rootState, rootGetters) => {
      return rootState.dataList.Status.filter(
        (s) => !rootState.hiddenStatuesFromWorklist.includes(s.Id)
      );
    },
    showDepartmentFilter: (state, getters, rootState, rootGetters) => {
      if (
        rootState.customerSettings &&
        rootState.customerSettings.departments &&
        rootState.customerSettings.departments.length < 2
      )
        return false;
      if (rootState.currUser.canViewAllDepartments) return true;
      if (
        rootState.currUser.departmentIds &&
        rootState.currUser.departmentIds.length < 2
      )
        return false;
      return true;
    },
    workersSortedByDepartment: (state, getters, rootState, rootGetters) => {
      const sortedWorkers = rootGetters.currUserDeptWorkers || [];
      const userCountObject = rootState.userCounts.find(
        (u) => u.paPersonId == rootState.currUser.paPersonId
      );

        let appendedWorkers = [
          new Worker({
            firstName: "Unassigned",
            lastName: null,
            fullName: "Unassigned",
            paPersonId: "null",
            currentRequests: rootGetters.requestCounts.unassigned,
            disabled: false,
          }),
          new Worker({
            firstName: "Assigned To Me",
            lastName: null,
            fullName: "Assigned To Me",
            paPersonId: "ASSIGNED_TO_ME",
            currentRequests: userCountObject?.count || 0,
            disabled: false,
          }),
        ];

        sortedWorkers?.forEach(sortedWorker => {
          appendedWorkers.push(
            new Worker({
                ...sortedWorker,
                fullName: `${sortedWorker.firstName} ${sortedWorker.lastName}`
            })
          )
        })

        return appendedWorkers;
    },
    submissionFilterOptions: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.sortedPortalList || !rootState.submissionMethods)
        return [];
      let portalValues = rootGetters.sortedPortalList.map((p) => {
        p.displayName = p.description;
        return p;
      });
      let methodValues = rootState.submissionMethods
        .map((m) => m)
        .filter((m) => m.name !== "Portal" && m.name !== "Direct");

      let directValues =
        rootState.customerSettings.allowedDirectSubmissionMethods;

      return [...portalValues, ...methodValues, ...directValues];
    },
    submissionStatusFilterOptions: (state, getters, rootState, rootGetters) => {
      if (!rootState.submissionStatusCategories.length) return [];
      return rootState.submissionStatusCategories.filter(
        (c) => c.submissionStatusCategoryId !== 0
      );
    },
    /**
     * Converts saved worklist filter names to lower case.
     */
    worklistFilterNamesLowered: (state, getters, rootState, rootGetters) => {
      if (!rootState.customUserFilters) return false;
      return rootState.customUserFilters.map((f) => f.name.toLowerCase());
    },
    /**
     * Returns a list of matching matching filters. Will return an empty
     * array if no match was found.
     */
    getMatchingFilterFromCurrent: (state, getters, rootState, rootGetters) => {
      const allFilters = [
        ...rootState.customUserFilters,
        ...rootState.systemFilters,
      ];

      return allFilters.filter((wf) => {
        let keyMatches = [];
        const filterObj = JSON.parse(wf.filter);
        getters.filterKeys.forEach((f) => {
          if (
            (!filterObj[f] && !getters.currentFilter[f]) ||
            (filterObj[f] &&
              getters.currentFilter[f] &&
              filterObj[f].length === getters.currentFilter[f].length &&
              filterObj[f].every((value) =>
                getters.currentFilter[f].includes(value)
              ))
          ) {
            keyMatches.push(true);
          } else {
            keyMatches.push(false);
          }
        });

        if (getters.filterKeys && keyMatches.includes(false)) return false;
        return true;
      });
    },
    /**
     * Returns true if the current filter selected is an existing filter,
     * otherwise will return false if it does not exist.
     */
    currentFilterIsExistingFilter: (state, getters, rootState, rootGetters) => {
      if (!getters.currentFilter || !rootState.customUserFilters) return false;

      const filterMatches = getters.getMatchingFilterFromCurrent;
      return filterMatches && filterMatches.length > 0;
    },
    /**
     * Returns a formatted version of the current filter.
     * formatCurrentFilter is located in the paginatedFilterMapping service.
     *
     * Filters are converted into an array of filter values:
     *
     * [
     *    "Field": <tokenauth-filter-value-name>
     *    "Value": <selected-values-in-filter>
     *    "Operator": <EQ|BETWEEN|WITHIN|CONTAINS|NEQ>
     * ]
     */
    formattedCurrentFilter: (state, getters, rootState, rootGetters) => {
      return PaginatedFilterMapping.formatCurrentFilter(getters.currentFilter, state.filterUpdates);
    },
    filteredPatientLastNameList: (state, getters, rootState, rootGetters) => {
      if (
        state.filters.filterByPatientLastName &&
        state.filters.filterByPatientLastName.length > 0
      ) {
        if (Array.isArray(state.filters.filterByPatientLastName)) {
          return state.filters.filterByPatientLastName[0];
        }
        return state.filters.filterByPatientLastName;
      }
      return null;
    },
  },
};
