import axios from 'axios'
import ENV_CONFIG from '@/config/environment'

const API = ENV_CONFIG.env_api

function initialState() {
    return {
        messages: false,
        isDetailsShown: false,
        detailsToShow: "",
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        GET_UMS_MESSAGES: async ({ commit, dispatch }, isIE) => {
            await axios.get(`${API}WorkDashboard/GetUMSMessages/${isIE}`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            }).then( 
                (response) => {
                    commit('SET_UMS_MESSAGES', response.data)
                },

                (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err
                }
            )
        },
        REMOVE_UMS_MESSAGE: async ({ commit, state }, messageId) => {
            if (state.messages){
                const message = state.messages.find(message => message.messageId === messageId);
                if (message.hasClearFlag) {
                    await axios.put(`${API}WorkDashboard/ClearUMSMessage/${messageId}`, null, {
                        headers: {
                            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        }
                    }).then( 
                        (response) => {},
        
                        (err) => {
                            window.console.log(err)
                        }
                    )
                }
                commit(
                    'SET_UMS_MESSAGES',
                    state.messages.filter( message => message.messageId !== messageId )
                )
                
            }
            
        },
        TOGGLE_DISPLAY_DETAILS: ({ state }, { shown, details = "" }) => {
            state.isDetailsShown = shown;
            state.detailsToShow = details;
        }
    },

    mutations: {
        SET_UMS_MESSAGES: (state, messages) => {
            state.messages = messages
        }
    }
}