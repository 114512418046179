import axios from 'axios'
import ENV_CONFIG from '@/config/environment'

const apiPath1 = ENV_CONFIG.env_api;

export class AuthApiService {

    static createToken(params) {
        return axios.post(`${apiPath1}auth/CreateToken`, params)
    }

    static updateTokenForLoggedInUser(params) {
        return axios.post(`${apiPath1}auth/UpdateTokenForLoggedInUser`, params, {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
        })
    }

    static getPossibleTwoFaMethods() {
        return axios.get(`${apiPath1}Auth/GetPossibleTwoFactorMethods`, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                'Pragma': 'no-cache'
            }
        })
    }

    static resetPassword(params) {
        return axios.post(`${apiPath1}auth/ResetPassword`, params)
    }

    static updatePassword(params) {
        return axios.post(`${apiPath1}Profile/UpdatePassword`, params, {
            headers: {
              'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
              'Pragma': 'no-cache'
            }
          })
    }

    static sendPasswordResetLink(params) {
        return axios.post(`${apiPath1}auth/SendPasswordResetLink`, params)
    }

    static sendRecoveryUsernameEmail(params) {
        return axios.post(`${apiPath1}auth/SendRecoverUserNameEmail`, params)
    }
}