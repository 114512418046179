export class CacheService {
  // Default timeToLiveSeconds = 86400 (one day)
  static setItem(key, value, timeToLiveSeconds = 86400) {
    const item = {
      value: value,
      expiry: timeToLiveSeconds ? Date.now() + timeToLiveSeconds * 1000 : null,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  static getItem(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    if (item.expiry && Date.now() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
  
  static removeItem(key) {
    localStorage.removeItem(key);
  }
  
  static clear() {
    localStorage.clear();
  }
};