import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatPhoneNumber', function (value) {
    if(!value) return ''
    
    let clean = (""+value).replace(/\D/g, '');
    let split = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
    
    // return raw value if we can't split on (3) 3-4 format
    if(!split || !split.length) return `${value}`
    
    let formatted = "(" + split[1] + ") " + split[2] + "-" + split[3];
    return formatted;
  })

Vue.filter('formatDateTime', function (value) {
    if(!value) return ''
    return moment.utc(value).local().format("MM/DD/YYYY h:mm A");
  })

  Vue.filter('durationFromNow', function (milliseconds) {
    if (!milliseconds || milliseconds < 0) return '0 seconds'
    return moment.duration(milliseconds).humanize({ s: 59, ss: 0 })
  })

  Vue.filter('lowerCase', function (word){
    return word ? word.toLowerCase() : '';
  })

  Vue.filter('captilizeFirstLetter', function (word){
    return word ? word.charAt(0).toUpperCase() + word.slice(1) : ''
  })

  Vue.filter('formatDate', function (value) {
    if(!value) return ''
    return moment.utc(value).format("MM/DD/YYYY");
  })

  Vue.filter('prettifyCptCodes', function (value) {
    if (!value) return 'N/A'

    const cptCodes = value.split(',')

    const group = cptCodes.reduce((acc, value) => {
      const currentValue = value.trim()

      if (!acc[currentValue]) {
        acc[currentValue] = [currentValue]
      } else {
        acc[currentValue].push(currentValue)
      }
      return acc
    }, {})

    return Object.keys(group)
                 .map(key => `${key}${group[key].length > 1 ? ` (Qty: ${group[key].length})` : ''}`)
                 .join(', ')
  })