import Vue from "vue";
import jwtDecode from 'jwt-decode'
import router from '@/router'
import moment from 'moment'
import ENV_CONFIG from '@/config/environment'
import { AuthApiService } from "@/services/authApi";
import { ErrorService } from "@/services/error";

const non2faUrl = ENV_CONFIG.non2faUrl;

function initialState() {
  return {
    twoFAMethods: false,
    currUserID: parseInt(sessionStorage.getItem("currUserId")),
    timerInterval: 30000,
    loggedIn: false,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    AUTH_USER: async function ({ commit, dispatch }, params) {
      try {
        const response = await AuthApiService.createToken(params);

        const decodedToken = jwtDecode(response.data.token);
        const twoFactorVerified = decodedToken.TwoFactorVerified.toLowerCase();

        dispatch("SET_USER_SESSION", {
          ...response.data,
          twoFactorVerified: twoFactorVerified,
        }).then(() => {
          // set the authenticated context after the session has been set
          Vue.appInsights.setAuthenticatedUserContext(
            response.data.paPersonId,
            response.data.customerId
          );

          // take the user to the worklist
          router.push("/");
        });
      } catch (error) {

        if (ErrorService.isUnAuthorized(error) && ErrorService.hasPasswordExpired(error)) {
            commit("SET_GLOBAL_MESSAGE", [
              "error",
              "Your password has expired.",
            ], { root: true });
            
            return Promise.reject(new Error("password_expired"));
        }

        if (
          ErrorService.isBadRequest(error) ||
          ErrorService.isUnAuthorized(error)
        ) {
          commit("SET_GLOBAL_MESSAGE", [
            "error",
            "Invalid username or password",
          ], { root: true });
          return Promise.reject(new Error("auth"));
        }

        if (ErrorService.isLocked(error)) {
          const currentDateTime = moment();
          const unlockDate = moment(error.response.data);
          const diff = currentDateTime.to(unlockDate);

          commit("SET_GLOBAL_MESSAGE", [
            "error",
            `Account locked. Too many failed attempts. Try again ${diff}`,
          ], { root: true });
          return;
        }

        if (ErrorService.preconditionRequired(error)) {
          commit("SET_GLOBAL_MESSAGE", [
            "error",
            "Account setup incomplete. Password reset required.",
          ], { root: true });
          return;
        }

        if (ErrorService.isUnavailableForLegalReasons(error)) {
          commit("SET_GLOBAL_MESSAGE", [
            "error",
            `You are attempting to login with non two-factor authentication credentials. Please use <a href="${non2faUrl}">${non2faUrl}</a> to sign in using your non 2fa credentials.`,
            null,
          ], { root: true });
          return;
        }

        if (ErrorService.hasInsecureConnection(window)) {
          commit("SET_GLOBAL_MESSAGE", [
            "error",
            `${error.message}. Insecure connection, you must use HTTPS`,
          ], { root: true });
          return;
        }

        commit("SET_GLOBAL_MESSAGE", [
          "error",
          `Unknown ${error.message}. Check that you are connected to the internet, using HTTPS and that your network IP is whitelisted.`,
        ], { root: true });
      }
    },
    SET_USER_SESSION: function ({ commit }, params) {
      sessionStorage.setItem("token", params.token);
      sessionStorage.setItem("expiration", params.expiration);
      sessionStorage.setItem("currUserId", params.paPersonId);
      sessionStorage.setItem("customerId", params.customerId);
      sessionStorage.setItem("twoFactorRequired", params.twoFactorRequired);
      sessionStorage.setItem("twoFactorEnabled", params.twoFactorEnabled);
      sessionStorage.setItem("twoFactorVerified", params.twoFactorVerified);
      sessionStorage.setItem("last2FAMethod", params.last2FAMethod);

      commit("SET_CURRENT_USER_ID", params.paPersonId);
      commit("SET_TIMER_INTERVAL", 30000);
    },
    REFRESH_AUTH_TOKEN: async function ({ commit, dispatch }) {
      const params = {
        token: sessionStorage.getItem("token"),
        paPersonId: sessionStorage.getItem("currUserId"),
      };

      try {
        const response = await AuthApiService.updateTokenForLoggedInUser(
          params
        );

        const decodedToken = jwtDecode(response.data.token);
        const twoFactorVerified = decodedToken.TwoFactorVerified.toLowerCase();

        dispatch("SET_USER_SESSION", {
          ...response.data,
          twoFactorVerified: twoFactorVerified,
        });
      } catch (error) {
        commit("SIGN_OUT");
      }
    },
    CHECK_SESSION_TOKEN: function ({ commit }) {
      const token = sessionStorage.getItem("token");
      const expires = sessionStorage.getItem("expiration");
      if (token && moment().utc().format() < expires) {
        commit("SIGN_IN");
        commit("SET_GLOBAL_MESSAGE", ["error", "", "timer"], { root: true });
      } else {
        commit("SIGN_OUT");
      }
    },
    GET_2FA_METHODS: async function ({ commit }) {
      try {
        const response = await AuthApiService.getPossibleTwoFaMethods();
        commit("SET_2FA_METHODS", response.data);
      } catch (error) {
        if (err.response.status === 401) dispatch("SIGN_OUT");
        window.console.log(err);
      }
    },
    SIGN_OUT: function ({ commit, dispatch }) {
      //clear last viewed flag

      commit("SIGN_OUT");
      dispatch("CLEAR_ROOT_STATE", null, { root: true });
    },
    SET_2FA_METHODS: ({ commit }, methods) => {
      commit("SET_2FA_METHODS", methods);
    },
  },
  mutations: {
    SET_CURRENT_USER_ID: (state, userId) => {
      state.currUserID = userId;
    },
    SET_TIMER_INTERVAL: (state, time) => {
      state.timerInterval = time;
    },
    SIGN_IN: (state) => {
      state.loggedIn = true;
    },
    SET_2FA_METHODS: (state, methods) => {
      state.twoFAMethods = methods;
    },
    SIGN_OUT: (state) => {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
      sessionStorage.clear();
    },
  },
  getters: {}
};
