import axios from 'axios'

import ENV_CONFIG from '@/config/environment'
const API = ENV_CONFIG.env_api

export class CustomerConfigurationsApi {
    static getCustomerConfig(customerId) {
        return axios.get(`${API}CustomerConfigurations/GetCustomerConfig/${customerId}`, {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
        })
    }
}