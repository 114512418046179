<template>
  <div class="genericLoader" v-bind:class="this.displaySize">
      <span class="fa-stack noIE">
        <i class="far fa-circle fa-stack-1x"></i>
        <i class="fa fa-spinner-third fa-spin fa-stack-1x"></i>
        <i class="far fa-spinner fa-spin noIE fa-stack-1x"></i>
      </span>
      <h4><slot>Loading...</slot></h4>
  </div>
</template>

<script>


export default {
  name: 'GenericLoader',
  props: ['displaySize'],
  destroyed() {
    const loaderEvent = new Event('loaderFinished');
    performance.mark("loaderFinished");
    loaderEvent.loaderType = this.$el.innerText;
    window.dispatchEvent(loaderEvent);
  },
  data: function(){
    return {
    }
  },
}
</script>
