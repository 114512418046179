import { CustomerConfigurationsApi } from '@/services/customerConfigurationsApi'
import { DISPLAY_CONFIGS } from '@/mixins/customerConfigDisplay'

const initialState = () => {
    return {
        customerConfig: null,
        displayConfig: null,
        tab: null
    }
}
/*
    Ensures name, label, and inputType are set on each entry of the
    'configs' property on a 'container' entry in DISPLAY_CONFIGS
*/
const fixDisplayConfig =  (configs, configId) => {
    const aDisplayConfig = configs[configId] || {};
    if(!aDisplayConfig.name || aDisplayConfig.name === '') {
        // if no input name defined, use config id
        aDisplayConfig.name = configId;
    }
    if(!aDisplayConfig.label || aDisplayConfig.label === '') {
        // if no label defined, generate one by adding spaces to camelCase configId
        aDisplayConfig.label = configId.replace(/([A-Z])/g, " $1");
    }
    if(!aDisplayConfig.inputType || aDisplayConfig.inputType === '') {
        // no input type defined, at least display as static text
        aDisplayConfig.inputType = "static-text";
    }
    return aDisplayConfig
}

/*
    Ensures header is set and path is minimally null on each
    'container' entry in DISPLAY_CONFIGS. Additionally,
    fixes up each entry in 'configs'
*/
const fixContainer =  (container) => {
    const fidxedConfigs = {}
    if(!container.header) {
        // no header defined, generate one by the right-most portion of the '.' seperated path...
        if(container.path) {
            const pathPatrs = container.path.split('.')
            container.header = pathPatrs[pathPatrs.length-1].replace(/([A-Z])/g, " $1");
        } else {
            // ... or set a default value
            container.header = 'Settings';
        }
    }
    if(!Object.hasOwn(container, 'path')) {
        // ensure path is at least defined as null
        container.path = null
    }
    // fix up each value in container.configs 
    const configKeys = Object.keys(container.configs)
    configKeys.forEach(configKey => {
        fidxedConfigs[configKey] = fixDisplayConfig(container.configs, configKey)
    })           
    container.configs = fidxedConfigs;
    return container
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        GET_CUSTOMER_CONFIG: async ({ commit }, customerId) => {
            try {
                const response = await CustomerConfigurationsApi.getCustomerConfig(customerId)
                commit('SET_CUSTOMER_CONFIG', response.data)
            } catch (error) {
                console.warn(error)
            }
        },
        SET_CUSTOMER_CONFIG_TAB: async ({ commit }, tab) => {
            try {
                commit('SET_TAB', tab)
            } catch (error) {
                console.warn(error)
            }
        }
    },
    mutations: {
        SET_CUSTOMER_CONFIG: (state, customerConfig) => {
            const displayConfig = []
            // filter DISPLAY_CONFIGS for the tab we're displaying
            const filteredConfigs = DISPLAY_CONFIGS.filter(elm => {
                return elm.tab === state.tab
            })

            let skipDataIngestFields = false;
            // fix up each entry in DISPLAY_CONFIGS for the tab we're displaying

            filteredConfigs.forEach(container => {  
                const aFixedContainer = fixContainer(container)
                displayConfig.push(aFixedContainer)
            })
            state.displayConfig = displayConfig
            state.customerConfig = customerConfig
        },
        SET_TAB: (state, tab) => {
            state.tab = tab
            state.displayConfig = null
        }
    }
}