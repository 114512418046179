import axios from 'axios';
import ENV_CONFIG from '@/config/environment';
import fd from "form-data";
import { CACHE_KEYS } from '@/shared/constants/cacheKeys'
import { CacheService } from '@/services/cacheService'

const API = ENV_CONFIG.env_api

function initialState() {
    return {
        gettingCustomerLogo: true,
        currentCustomerLogo: null,
        badImage: false,
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        GET_CUSTOMER_LOGO: async ({ commit, dispatch }) => {
            var customerId = sessionStorage.getItem('customerId')
            var cacheKey = CACHE_KEYS.CustomerLogo(customerId);

            var logo = CacheService.getItem( cacheKey );

            if ( logo != null ){
                commit("SET_CUSTOMER_LOGO", logo); 
                commit("SET_GETTING_CUSTOMER_LOGO", false); 
            } else {
                commit("SET_GETTING_CUSTOMER_LOGO", true);
                axios.get(`${API}WorkDashboard/GetCustomerLogo`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache',
                    }
                }).then( 
                    (response) => {
                        commit("SET_CUSTOMER_LOGO", response.data); 
                        commit("SET_GETTING_CUSTOMER_LOGO", false); 

                        CacheService.setItem( cacheKey, response.data )
                    },
                    (err) => {
                        commit("SET_GETTING_CUSTOMER_LOGO", false);
                        if (err.response.status === 401) 
                            dispatch('auth/SIGN_OUT')
                        
                        window.console.log(err)
                        return err
                    }
                )
            }
        },

        UPDATE_CUSTOMER_LOGO: async ({ commit, dispatch }, { file, data, name }) => {
            const fdData = new fd();
            fdData.append("file", file, name);
            await axios.post(`${API}/CustomerServiceManager/UpdateCustomerLogo`, fdData, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache',
                    'Content-Type': `multipart/form-data;boundary=${data._boundary}`
                }
            }).then(_ => {
                commit("SET_CUSTOMER_LOGO", data);
            }).catch(err => {
                if (err.response && err.response.status === 401) 
                    dispatch('auth/SIGN_OUT')

                else if (err.response && err.response.status === 400)
                    commit("SET_BAD_IMAGE", true)

                else
                    commit("SET_GLOBAL_MESSAGE", ["error", "Unable to upload customer logo"])

                window.console.log(err)
                return err
            })
        },

        DELETE_CUSTOMER_LOGO: async ({commit, dispatch}) => {
            await axios.post(`${API}/CustomerServiceManager/ClearCustomerLogo`, null, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache',
                }
            }).then(_ => {
                commit("RESET")
            }).catch(err => {
                if (err.response && err.response.status === 401)
                    dispatch('auth/SIGN_OUT')
                
                commit("SET_GLOBAL_MESSAGE", ["error", "Unable to delete customer logo."])
                window.console.log(err)
                return err
            })
        }
    },

    mutations: {
        SET_CUSTOMER_LOGO: (state, currentCustomerLogo) => {
            state.currentCustomerLogo = currentCustomerLogo
        },
        SET_GETTING_CUSTOMER_LOGO: (state, status) => {
            state.gettingCustomerLogo = status
        },
        SET_BAD_IMAGE: (state, bool) => {
            state.badImage = bool
        },
        RESET: (state) => {
            state.currentCustomerLogo = null
            state.gettingCustomerLogo = false
            state.badImage = false
        }
    }
}