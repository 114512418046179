

export class Worker {
    constructor(params) {
        this.canViewAllDepartments = params?.canViewAllDepartments
        this.currentRequests = params?.currentRequests
        this.departmentId = params?.departmentId
        this.departmentIds = params?.departmentIds
        this.departmentName = params?.departmentName
        this.email = params?.email
        this.faxNumber = params?.faxNumber
        this.firstName = params?.firstName
        this.fullName = params?.fullName
        this.fullUserName = params?.fullUserName
        this.isValid = params?.isValid
        this.lastLoginTime = params?.lastLoginTime
        this.lastName = params?.lastName
        this.linkedAARCustomerIds = params?.linkedAARCustomerIds || []
        this.listOfRoles = params?.listOfRoles || ""
        this.organization = params?.organization
        this.paPersonId = params?.paPersonId
        this.passwordExpirationDate = params?.passwordExpirationDate
        this.phoneNumber = params?.phoneNumber
        this.systemManagedAccount = params?.systemManagedAccount
        this.userName = params?.userName

    }
}