export default {
    methods: {
        deepFreeze: function(o) {
            let that = this
            Object.freeze(o);

            Object.getOwnPropertyNames(o).forEach(function (prop) {
                if (o.hasOwnProperty(prop)
                    && o[prop] !== null
                    && (typeof o[prop] === "object" || typeof o[prop] === "function")
                    && !Object.isFrozen(o[prop])) {
                    that.deepFreeze(o[prop]);
                }
            });

            return o;
        }
    }
}