import axios from "axios";
import ENV_CONFIG from "@/config/environment";

const API = ENV_CONFIG.env_api;

function initialState() {
  return {
    displayAccountLinkConfirmationModal: false,
    displayAbandonWarningModal: false,
    displayFinalizedSubmission: false,
    isAccountNotLinked: false,
    attestationInProgress: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    CONFIRM_ACCOUNT_LINK: ({ commit }, params) => {
      axios
        .post(`${API}Gorilla/ConfirmAccountLink?token=${params.token}&linkAccount=${params.linkAccount}`, {}, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            Pragma: "no-cache",
          },
        })
        .then(
          (response) => {
            commit("SET_HAS_ACCOUNT_LINKED", true);
          },
          (err) => {
            commit("SET_HAS_ACCOUNT_LINKED", false);
            if (err.response.status === 401) dispatch("auth/SIGN_OUT");
          }
        );
    },
    DISPLAY_ABANDON_WARNING_MODAL: ({ commit }, displayAbandonWarningModal) => {
      commit('SET_DISPLAY_ABANDON_WARNING_MODAL', displayAbandonWarningModal)
    },
    DISPLAY_FINALIZED_SUBMISSION: ({ commit }, displayFinalizedSubmission) => {
      commit('SET_DISPLAY_FINALIZED_SUBMISSION', displayFinalizedSubmission)
    },
    ATTESTATION_IN_PROGRESS: ({ commit }, attestationInProgress) => {
      commit('SET_ATTESTATION_IN_PROGRESS', attestationInProgress)
    }
  },
  mutations: {
    SET_ATTESTATION_IN_PROGRESS: (state, attestationInProgress) => {
      state.attestationInProgress = attestationInProgress
    },
    SET_ACCOUNT_LINK_CONFIRMATION_MODAL: (
      state,
      displayAccountLinkConfirmationModal
    ) => {
      state.displayAccountLinkConfirmationModal = displayAccountLinkConfirmationModal;
    },
    SET_DISPLAY_ABANDON_WARNING_MODAL: (state, displayAbandonWarningModal) => {
      state.displayAbandonWarningModal = displayAbandonWarningModal
    },
    SET_DISPLAY_FINALIZED_SUBMISSION: (state, displayFinalizedSubmission) => {
      state.displayFinalizedSubmission = displayFinalizedSubmission
    },
    SET_NOT_LINKED_ACCOUNT: (state, isLinked) => {
      state.isAccountNotLinked = isLinked
    }
  },
};
