

export class UserAssignmentRule {
    constructor({
        autoAssignmentRuleId,
        customerId,
        departmentId,
        priority,
        ruleType,
        urgencyValues,
        payerValues,
        orderingProviderValues,
        servicingFacilityNameValues,
        servicingFacilityNPIValues,
        emrOrderingDepartmentValues,
        patientLastNameValues,
        codeValues,
        usersList,
        systemRule,
        isActive,
        isDeleted
    }) {
      this.autoAssignmentRuleId = autoAssignmentRuleId
      this.customerId = customerId
      this.departmentId = departmentId
      this.priority = priority
      this.ruleType = ruleType
      this.urgencyValues = urgencyValues
      this.payerValues = payerValues
      this.orderingProviderValues = orderingProviderValues
      this.servicingFacilityNameValues = servicingFacilityNameValues
      this.servicingFacilityNPIValues = servicingFacilityNPIValues
      this.emrOrderingDepartmentValues = emrOrderingDepartmentValues
      this.patientLastNameValues = patientLastNameValues
      this.codeValues = codeValues
      this.usersList = usersList
      this.systemRule = systemRule
      this.isActive = isActive
      this.isDeleted = isDeleted
    }
}

export class UrgencyType {
    constructor({ code, displayName }) {
        this.code = code
        this.displayName = displayName
    }
}

export class CptType {
    constructor({ code }) {
        this.code = code
    }
}

export class Conditions {
    constructor({
        selectedRuleType,
        filterByRule,
        filterByPayer,
        filterByProvider,
        filterByUrgency,
        filterByCPT,
        filterByServicingFacilityName,
        filterByEmrOrderingDepartment,
        filterByServicingFacilityNPI,
        filterByPatientLastName,
        selectedFacilityNames,
        selectedFacilityNPIs,
        selectedOrderingDepartments,
        filterByAssigned,
        selectedDepartment,
        patientLastNameStartRange,
        patientLastNameEndRange,
        isFirstCollectionEntryValid,
        isSecondCollectionEntryValid,
        autoAssignmentRuleId,
        isActive,
        isDeleted
    }) {
        this.selectedRuleType = selectedRuleType || '0'
        this.selectedDepartment = selectedDepartment || '0'
        this.filterByRule = filterByRule || []
        this.filterByPayer = filterByPayer || []
        this.filterByProvider = filterByProvider || []
        this.filterByUrgency = filterByUrgency || []
        this.filterByCPT = filterByCPT || []
        this.filterByServicingFacilityName = filterByServicingFacilityName || []
        this.filterByEmrOrderingDepartment = filterByEmrOrderingDepartment || []
        this.filterByServicingFacilityNPI = filterByServicingFacilityNPI || []
        this.filterByPatientLastName = filterByPatientLastName || []
        this.selectedFacilityNames = selectedFacilityNames || []
        this.selectedFacilityNPIs = selectedFacilityNPIs || []
        this.selectedOrderingDepartments = selectedOrderingDepartments || []
        this.filterByAssigned = filterByAssigned || []
        this.patientLastNameStartRange = patientLastNameStartRange || ''
        this.patientLastNameEndRange = patientLastNameEndRange || ''
        this.isFirstCollectionEntryValid = isFirstCollectionEntryValid || true
        this.isSecondCollectionEntryValid = isSecondCollectionEntryValid || true
        this.autoAssignmentRuleId = autoAssignmentRuleId
        this.isActive = isActive
        this.isDeleted = isDeleted
    }
}