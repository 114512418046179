

export class Notification {
    constructor(params) {
        this.id = params.id;
        this.when = params.when;
        this.response = params.response;
        this.from = params.from;
        this.filename = params.filename;
    }
}