function initialState() {
    return {
        refreshBrowser: false
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {},
    mutations: {
        SET_REFRESH_BROWSER: (state, bool) => {
            state.refreshBrowser = bool
        }
    }
}