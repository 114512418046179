import axios from 'axios'
import ENV_CONFIG from '@/config/environment'
import {ReportApiService} from "@/services/reportApi";

const API = ENV_CONFIG.env_api

const authDeterminationAndStatusCheckReportList = [
    "GetAuthDeterminationTrReport",
    "GetAutomatedStatusChecksTrReport",
    "GetAuthDeterminationTrReportBg",
    "GetAutomatedStatusChecksTrReportBg"
];

export function isAuthDeterminationOrStatusCheckReport(reportAddress) {
    return authDeterminationAndStatusCheckReportList.includes(reportAddress);
}

function initialState() {
    return {
        columns: false,
        reportTitle: false,
        reportData: false,
        reportCategoryData: [],
        reportTaskRunnerData: [],
        reportError: false,
        reportRunning: false,
        isRunningInBackground: false,
        displayTotal: false,
        reportResultsId: 0,
        notificationReportName: false,
        notificationStartEndStr: false,
        isBackgroundReport: false,
        savedDateRange: '',
        additionalData: false,
        startTime: false,
        paramsData: '',
        links:[],
        backgroundReportsProcessing: [],
        backgroundReportsFinished: [],
        backgroundReportsError: [],
        checkDownloadStatusRunning: false
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        GET_DYNAMIC_REPORT: function ({dispatch}, params) {
            if (params.post) {
                dispatch('POST_REPORT', params);
            } else {
                dispatch('GET_REPORT', params);
            }
        },
        GET_REPORT: async function ({commit, state}, params) {
            // startTime only used when running reports in the background, the  notification system uses it to inform
            // the user how long the report has been running.
            const startTime = Date.now();

            const finishedReport = state.backgroundReportsFinished.find(r =>
                r.reportAddress === params.reportAddress && r.paramsData === params.data
            );

            if (finishedReport) {
                // It looks like they are trying to run the report that has already been run, since the data is in
                // the system, just display it.  The vue code calls SET_REPORT_DATA_BY_ID to display a finished report,
                // so just make the call here with the data from the list, SET_REPORT_DATA_BY_ID will remove the row
                // from the list
                commit('SET_REPORT_DATA_BY_ID', parseInt(finishedReport.reportResultsId));
                commit('SET_REPORT_RUNNING', false);
                return true;
            }

            const runningReport = state.backgroundReportsProcessing.find(r =>
                r.reportAddress === params.reportAddress && r.paramsData === params.data
            );

            commit('SET_REPORT_IS_BACKGROUND_REPORT', params.isBackgroundReport);
            commit('SET_REPORT_RUNNING', true);

            if (runningReport) {
                // It looks like they are trying to run the report that is already in progress,
                // revert back to the state before the left the page so the system continues to
                // wait on the report to finish.
                const filteredList = state.backgroundReportsProcessing.filter(item => item !== runningReport);
                commit('SET_BACKGROUND_REPORTS_PROCESSING', filteredList);
                // Since the user is now viewing it, remove it from the notifications
                commit('SET_RUNNING_REPORT_ACTIVE', runningReport);
                return true;
            }

            await axios.get(`${API}Report/${params.reportAddress}?${params.data}`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache',
                    'Content-Type': 'application/json'
                }
            }).then((response) => {

                response.data.reportAddress = params.reportAddress

                if (isAuthDeterminationOrStatusCheckReport(params.reportAddress) && typeof params.categoryId === 'number') {
                    commit('SET_REPORT_CATEGORY_DATA', response.data)
                    if (params.trCategoryCode) {
                        commit('SET_REPORT_TASKRUNNER_DATA', response.data)
                    }
                } else {
                    // The following params are only used when running a report in the background
                    response.data.startTime = startTime;
                    response.data.paramsData = params.data;

                    commit('SET_REPORT_DATA', response.data);

                    if (params.reportAddress === "GetFacilityReport") {
                        commit('SET_DISPLAY_TOTAL', true);
                    }

                    commit('SET_REPORT_RUNNING', response.data.isBg);
                }
            }, (err) => {
                commit('SET_REPORT_RUNNING', false)
                commit('SET_REPORT_ERROR', err.response.data)
                if (err.response.status === 401) dispatch('auth/SIGN_OUT')
            })
        },
        GET_EXPORT_REPORT_ID: async function ({commit}, params) {
            return await axios.get(`${API}Report/${params.reportExportAddress}?${params.data}&OutputExcel=false`, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache',
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                return response.data.reportResultsId
            }, (err) => {
                commit('SET_REPORT_ERROR', err.response.data)
                if (err.response.status === 401) dispatch('auth/SIGN_OUT')
            })
        },
        POST_REPORT: async function ({commit}, params) {
            // At this point in time no background reports run via POST.  If this is needed, please see the extra values
            // being set in the GET to enable this to work with background reports

            commit('SET_REPORT_RUNNING', true)
            await axios.post(`${API}Report/${params.reportAddress}`, params.data, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    commit('SET_REPORT_RUNNING', false)
                    commit('SET_REPORT_DATA', response.data)
                }, (err) => {
                    commit('SET_REPORT_RUNNING', false)
                    commit('SET_REPORT_ERROR', err.response.data)
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                })
        },
        SET_COLUMN_RENDER_AS_COLUMN: async function ({commit}, params) {
            commit('SET_COLUMN_RENDER_AS_COLUMN', params)
        },
        CHECK_DOWNLOAD_STATUS: async function ({commit, state, dispatch}, report) {
            if (!state.checkDownloadStatusRunning) {
                commit('SET_CHECKDOWNLOADSTATUSRUNNING', true);
                try {
                    const id = report.reportResultsId;
                    const statusResponse = await ReportApiService.getStatus(id);
                    const data = statusResponse.data;
                    if (data.isProcessComplete) {
                        if (data.hasErrors === false) {
                            dispatch('BG_REPORT_FINISHED', data.report);
                        } else {
                            commit('SET_REPORT_BG_ERROR', id);
                        }
                    }
                } catch (error) {
                    console.error('Error checking download status:', error);
                } finally {
                    commit('SET_CHECKDOWNLOADSTATUSRUNNING', false);
                }
            }
        },
        REMOVE_FINISHED_REPORTS: ({commit, state}, report) => {
            const backgroundReportsFinishedList = state.backgroundReportsFinished.filter(
                r => r?.reportResultsId != report?.reportResultsId
            )

            commit('SET_BACKGROUND_REPORTS_FINISHED', backgroundReportsFinishedList);
        },
        REMOVE_FAILED_REPORTS: ({commit, state}, report) => {
            const backgroundReportsErrorList = state.backgroundReportsError.filter(
                r => r?.reportResultsId != report?.reportResultsId
            )

            commit('SET_BACKGROUND_REPORTS_ERROR', backgroundReportsErrorList);
        },
        BG_REPORT_FINISHED: ({commit, state}, reportPayload) =>
        {
            const reportResultId = parseInt(reportPayload.reportResultsId);

            const matchingReport = state.reportResultsId === reportResultId
                ? state
                : state.backgroundReportsProcessing.find(r => r.reportResultsId === reportResultId) || {};

            const reportAddress = matchingReport === state ? state.reportName : matchingReport.reportAddress;

            if(reportPayload.isBg === true)
            {
                const self = matchingReport.links?.self || null;

                if(self) {
                    const url = new URL(self, API).toString();
                    axios.get(url, {
                        headers: {
                            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                            'Pragma': 'no-cache',
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        response.data.reportAddress = reportAddress;
                        response.data.isRunningInBackground = false;
                        commit('SET_REPORT_DATA', response.data);
                        commit('SET_REPORT_RUNNING', response.data.isBg);
                        commit('SET_REPORT_IS_BACKGROUND_REPORT', false);
                    }, (err) => {
                        commit('SET_REPORT_RUNNING', false);
                        commit('SET_REPORT_ERROR', err.response.data);
                        commit('SET_REPORT_IS_BACKGROUND_REPORT', false);
                        if (err.response.status === 401) dispatch('auth/SIGN_OUT');
                    });
                } else {
                    commit('SET_REPORT_RUNNING', false)
                    commit('SET_REPORT_ERROR', 'Unable to find reference to report.')
                    commit('SET_REPORT_IS_BACKGROUND_REPORT', false);
                }
            }
            else
            {
                reportPayload.isRunningInBackground = false;
                reportPayload.reportAddress = reportAddress;
                // not a special report that needs further calls to display, set the reportData
                commit('SET_REPORT_DATA', reportPayload);
            }
        }
    },
    mutations: {
        SET_REPORT_DATA_BY_ID: (state, reportResultsId) => {
            function updateStateProperty(data, key, defaultValue = undefined) {
                return data.hasOwnProperty(key) ? data[key] : (defaultValue !== undefined ? defaultValue : initialState()[key]);
            }

            const reportData = state.backgroundReportsFinished.find(r => r.reportResultsId === reportResultsId);

            if (reportData) {
                // Since the user is now viewing it, remove it from the notifications
                state.backgroundReportsFinished = state.backgroundReportsFinished.filter(item => item !== reportData);

                state.columns = updateStateProperty(reportData, 'columns');
                state.reportTitle = updateStateProperty(reportData, 'title');
                state.reportData = updateStateProperty(reportData, 'reportData');
                state.reportResultsId = updateStateProperty(reportData, 'reportResultsId');
                state.reportName = updateStateProperty(reportData, 'reportAddress');
                state.additionalData = updateStateProperty(reportData, 'additionalData') || false;
                state.links = updateStateProperty(reportData, 'links');
                state.isRunningInBackground = false; // isRunningInBackground
                state.notificationReportName = '';
                state.notificationStartEndStr = '';

                // if the incoming data does not have a startTime, preserve the current value
                state.startTime = false;
            }
        },
        SET_REPORT_BG_ERROR: (state, reportResultsId) => {
            const runningReport = state.backgroundReportsProcessing.find(r => r.reportResultsId === reportResultsId);

            if (runningReport) {
                state.backgroundReportsProcessing = state.backgroundReportsProcessing.filter(item => item !== runningReport);
                state.backgroundReportsError.push(runningReport);
            } else {
                state.reportError = `Report Timed Out [${reportResultsId}]`;
            }
        },
        SET_REPORT_DATA: (state, data) => {
            // This method is used in two different ways, both synchronous and asynchronous:
            // synchronous: The original use, this is from the actions above.  The first if statement will always be
            //     false and the code below will set the state correctly to display the report
            // asynchronous: The newer way, both from the actions above and from the socketConnection.  The actions
            //     above will set enough info for the spinner to show that the report is being generated and save
            //     new values for the notifications.  If/when the user navigates away from the page the RESET is called
            //     and the report info is moved to the backgroundReportsProcessing array.  When the socket gets the
            //     finished report it also calls this method.
            //
            //     If the user is still on this page, the code will not go into the if(movedReport) statement below,
            //     rather it will populate the data below just like the calls from the actions above.
            //
            //     On the other hand, if the user has navigated away, the code will enter the if(movedReport) and move the
            //     report from the backgroundReportsProcessing to the backgroundReportsProcessing while adding the rest
            //     of the report info and exiting before setting the state.  It is important NOT to change that because
            //     the user could have navigated back to the report page and is now waiting on a new report.

            if (data.reportData && !data.isRunningInBackground) {
                // Find the report in backgroundReportsProcessing
                const movedReport = state.backgroundReportsProcessing.find(item => item.reportResultsId === data.reportResultsId);

                // If found move it to backgroundReportsFinished
                if (movedReport) {
                    state.backgroundReportsProcessing = state.backgroundReportsProcessing.filter(item => item !== movedReport);

                    movedReport.title = data.title;
                    movedReport.columns = data.columns;
                    movedReport.reportData = data.reportData;
                    movedReport.additionalData = data.additionalData;

                    state.backgroundReportsFinished.push(movedReport);

                    // exit now because the rest of the state will be set when/if the user
                    // clicks on the link to view the report
                    return;
                }
            }

            function updateStateProperty(data, key, defaultValue = undefined) {
                return data.hasOwnProperty(key) ? data[key] : (defaultValue !== undefined ? defaultValue : initialState()[key]);
            }

            state.columns = updateStateProperty(data, 'columns');
            state.reportTitle = updateStateProperty(data, 'title');
            state.reportData = updateStateProperty(data, 'reportData');
            state.reportResultsId = parseInt(updateStateProperty(data, 'reportResultsId'));
            state.reportName = updateStateProperty(data, 'reportAddress');
            state.additionalData = updateStateProperty(data, 'additionalData') || false;
            state.links = updateStateProperty(data, 'links');
            state.isRunningInBackground = data.isBg; // isRunningInBackground
            state.notificationReportName = updateStateProperty(data, 'notifN');
            state.notificationStartEndStr = updateStateProperty(data, 'notifSdEd');
            state.paramsData = updateStateProperty(data, 'paramsData');

            // if the incoming data does not have a startTime, preserve the current value
            state.startTime = updateStateProperty(data, 'startTime', state.startTime);
        },
        SET_REPORT_CATEGORY_DATA: (state, data) => {
            state.reportCategoryData.push(data)
        },
        SET_REPORT_TASKRUNNER_DATA: (state, data) => {
            state.reportTaskRunnerData.push(data)
        },
        SET_REPORT_ERROR: (state, err) => {
            state.reportError = err
        },
        SET_REPORT_RUNNING: (state, bool) => {
            state.reportRunning = bool
        },
        SET_REPORT_IS_BACKGROUND_REPORT: (state, isBackgroundReport) => {
            state.isBackgroundReport = isBackgroundReport
        },
        SET_DISPLAY_TOTAL: (state, bool) => {
            state.displayTotal = bool;
        },
        SET_COLUMN_RENDER_AS_COLUMN: (state, params) => {
            const {columnIndex, renderAsColumn} = params
            state.columns[columnIndex].renderAsColumn = renderAsColumn
        },
        SET_BACKGROUND_REPORTS_FINISHED: (state, backgroundReportsFinishedList) => {
            state.backgroundReportsFinished = backgroundReportsFinishedList;
        },
        SET_BACKGROUND_REPORTS_ERROR: (state, backgroundReportsErrorList) => {
            state.backgroundReportsError = backgroundReportsErrorList;
        },
        SET_BACKGROUND_REPORTS_PROCESSING(state, backgroundReportsProcessing) {
            state.backgroundReportsProcessing = backgroundReportsProcessing;
        },
        SET_RUNNING_REPORT_ACTIVE: (state, runningReport) => {
            // this is used in the case where a user tries to rerun a report that is already in process
            state.reportResultsId = runningReport.reportResultsId;
            state.isRunningInBackground = runningReport.isRunningInBackground;
            state.links = runningReport.links;
            state.notificationReportName = runningReport.name;
            state.notificationStartEndStr = runningReport.startEndStr;
            state.startTime = runningReport.when;
            state.reportName = runningReport.reportAddress;
            state.paramsData = runningReport.paramsData;
            state.additionalData = runningReport.additionalData;
            state.isBackgroundReport = runningReport.isBackgroundReport;
        },
        SET_CHECKDOWNLOADSTATUSRUNNING: (state, running) => {
            state.checkDownloadStatusRunning = running
        },
        RESET: (state, resetSavedDate) => {

            if (state.isRunningInBackground) {
                state.backgroundReportsProcessing = [
                    ...state.backgroundReportsProcessing,
                    {
                        reportResultsId: parseInt(state.reportResultsId),
                        links: state.links,
                        isRunningInBackground: state.isRunningInBackground,
                        name: state.notificationReportName,
                        startEndStr: state.notificationStartEndStr,
                        when: state.startTime,
                        reportAddress: state.reportName,
                        paramsData: state.paramsData,
                        additionalData: state.additionalData,
                        isBackgroundReport: state.isBackgroundReport
                    },
                ];

                state.isRunningInBackground = false;
            }

            let _savedDateRange = state.savedDateRange;

            const s = initialState()
            Object.keys(s).forEach(key => {
                if (key !== 'backgroundReportsProcessing' && key !== 'backgroundReportsFinished' && key !== 'backgroundReportsError') {
                    state[key] = s[key];
                }
            })

            state.savedDateRange = resetSavedDate ? '' : _savedDateRange;
        }
    }
}